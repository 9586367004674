/****************************************************
    Site styles
****************************************************/
import "./src/scss/app.scss"

/****************************************************
    Page transition 
    - fix scroll jump on page transition
****************************************************/
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const locationPath = location.pathname
  const prevLocationPath = prevRouterProps?.location?.pathname
  const savedPosition = getSavedScrollPosition(location) || [0, 0]

  // only scroll top if the page path has changed - not on query string update
  if (locationPath !== prevLocationPath) {
    setTimeout(() => {
      window.scrollTo({
        top: savedPosition[1],
        left: savedPosition[0],
        // behavior: "smooth",
      })
    }, 200)
  }
  return false
}

/****************************************************
    Page change 
****************************************************/
export function onRouteUpdate({ location, prevLocation }) {}
