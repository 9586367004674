exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/ContentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-data-page-tsx": () => import("./../../../src/templates/DataPage.tsx" /* webpackChunkName: "component---src-templates-data-page-tsx" */),
  "slice---src-components-layout-footer-country-tsx": () => import("./../../../src/components/Layout/FooterCountry.tsx" /* webpackChunkName: "slice---src-components-layout-footer-country-tsx" */),
  "slice---src-components-layout-footer-tsx": () => import("./../../../src/components/Layout/Footer.tsx" /* webpackChunkName: "slice---src-components-layout-footer-tsx" */),
  "slice---src-components-layout-header-tsx": () => import("./../../../src/components/Layout/Header.tsx" /* webpackChunkName: "slice---src-components-layout-header-tsx" */)
}

