import React from "react"
import { Slice } from "gatsby"

import Transition from "../components/Layout/Transition"

interface Props {
  children: React.ReactNode
  location: Location
  pageContext?: any
}

const defaultProps = {
  pageContext: {},
}

const Layout = ({ children, location }: Props) => {
  return (
    <>
      <Slice alias="header" pathname={location.pathname} />

      <Transition location={location}>{children}</Transition>

      {location.pathname === "/" ? (
        <Slice alias="footerCountry" />
      ) : (
        <Slice alias="footer" />
      )}
    </>
  )
}

Layout.defaultProps = defaultProps

export default Layout
